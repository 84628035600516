.ant-layout-sider {
    background: #FF0022 !important;
    box-shadow: 7px 6px 16px 0px #adadb0;
    margin-top: 30px;
}

.ant-layout {
    background: white;
}

.ant-descriptions-item-label {
    font-weight: bold;
    color: #282830 !important;
    margin-bottom: 10px;
}

.ant-btn-primary {
    background: #FF0022 !important;
    border: none;
}

.ant-divider {
    margin: 0px 0 !important;
    margin-bottom: 50px !important;
    background-color: #adadb0;
    height: 2px;
}

.no-ant-divider {
    margin-bottom: 25px !important;
}

.ant-table-thead .ant-table-cell {
    background-color: #FF0022;
    font-weight: bold;
    color: white;
}

.ant-table-column-has-sorters:hover,
th.ant-table-column-sort {
    background-color: #FF0022 !important;
}

.ant-descriptions {
    padding: 20px;
}

.ant-select {
    margin-top: 0px !important;
}

.ant-spin-dot-item {
    background-color: #FF0022 !important;
}

.ant-spin-text {
    color: #FF0022 !important;
}

.btn-toyota {
    background-color: white;
    color: #FF0022;
    border: 1px solid #FF0022;
}

.btn-toyota:focus {
    background-color: white;
    color: #FF0022;
    border: 1px solid #FF0022;
}

.btn-toyota:hover {
    background-color: #FF0022;
    color: white;
    border: 1px solid #FF0022;
}

.a-link {
    border: none;
    background-color: #FF0022;
    color: white;
    border-radius: 1.5rem !important;
    padding: 10px;
}

.downloadButton {
    display: block;
    position: relative;
    z-index: 999;
    margin-top: -30px;
    width: 60px !important;
    height: auto !important;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
    color: #282830;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    border: 1px solid #282830;
}

a {
    color: #282830;
}

a:hover {
    color: white;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.file-preview {
    margin: 0 10px;
}

.downloadButton {
    display: block;
    width: 100px;
    margin-top: -30px;
}

button {
    border-radius: 1.5rem !important;
}

body {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.ant-layout-header {
    border-bottom: 5px solid #FF0022 !important;
}

.ant-menu {
    padding: 10px;
    font-weight: bold;
    background: #FF0022 !important;
}

.ant-menu-item-selected {
    background-color: #d90022 !important;
    color: white;
}

.ant-row {
    margin-top: 15px;
}

.detail-box-large {
    box-shadow: 5px 5px 10px 0px #adadb0;
    padding: 20px;
    border-radius: 1rem;
    max-width: 100%;
}

.ant-table-filter-trigger {
    color: white;
}

.ant-table-column-sorters:hover,
.ant-table-column-sorter {
    color: white;
}

.detail-box {
    box-shadow: 5px 5px 10px 0px #adadb0;
    padding: 20px;
    border-radius: 1rem;
    max-width: 60%;
}

.filter-box {
    padding: 5px;
    padding-top: 20px;
    padding-left: 30px;
    box-shadow: 5px 5px 10px 0px #adadb0;
    border-radius: 0.5rem;
}

.marginTop {
    margin-top: 45px;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 8px;
}

// da qui ci sono le componenti lexus
.ant-layout-sider-lexus {
    background: #13248C !important;
    box-shadow: 7px 6px 16px 0px #adadb0;
    margin-top: 30px;
}

.ant-btn-primary-lexus {
    background: #13248C !important;
    border: none;
}

#th-lexus {
    th {
        background-color: #13248C;
        font-weight: bold;
        color: white;
    }

    th:hover {
        background-color: #13248C !important;
    }
}

#ul-lexus {
    ul {
        color: white;
        background-color: #13248C !important;
    }
}

#a-lexus {
    a {
        color: white;
    }

    a:hover {
        color: silver;
    }
}

.btn-lexus {
    background-color: white;
    color: #13248C;
    border: 1px solid #13248C;
}

.btn-lexus:focus {
    background-color: white;
    color: #13248C;
    border: 1px solid #13248C;
}

.btn-lexus:hover {
    background-color: #13248C;
    color: white;
    border: 1px solid #13248C;
}

.a-link-lexus {
    border: none;
    background-color: #13248C;
    color: white;
    border-radius: 1.5rem !important;
    padding: 10px;
}

.ant-layout-header-lexus {
    border-bottom: 5px solid #13248C !important;
}

.ant-menu-lexus {
    color: white;
    padding: 10px;
    font-weight: bold;
    background-color: #13248C !important;

    ul {
        color: white;
        background-color: #13248C !important;
    }

    .ant-menu-item-selected {
        color: white;
        background-color: #091561 !important;
    }

}

#spin-lexus {
    .ant-spin-dot-item {
        background-color: #13248C !important;
    }

    .ant-spin-text {
        color: #13248C !important;
    }
}

// toggle 

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: rgb(255, 0, 34);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}



.react-toggle--checked .react-toggle-track {
    background-color: rgb(16, 30, 142);
}



.react-toggle-track-check {
    position: absolute;
    width: 7px;
    height: 5px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 5px;
    height: 5px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 5px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #000000;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

.label-toggle {
    margin-left: 17%;
    display: flex;
    align-items: center;
}

.label-toggle>div {
    margin: 5px;
}

.toggle-container {
    margin-top: 13px;
}

.toggle {
    position: relative;
    left: 55%;
    top: 10px;
}

#th-lexus .ant-table-column-has-sorters:hover,
#th-lexus th.ant-table-column-sort {
    background-color: #13248C !important;
}

.plt-overflow-hidden{
    height: 65px;
    width: 120px;
    overflow: hidden;
}