.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  text-align: center; 

  .login-page_form-container {
    z-index: 10;
    position: absolute;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    padding: 50px 35px;

    .login-page_form-container_title {
      color: #2D3296;
      font-size: 36px;
    }

    .login-page_form-container_label {
      font-size: 22px;
    }

    Input {
      + p {
        margin-top: 20px;
      }
      + .login-button {
        margin-top: 40px;
      }
    }

    .login-button {
      width: 100% ;
      span {
        color: white;
        font-weight: bold;
      }
    }
  }
}



.wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.slideshow {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  &--hero {
    z-index: 3;
    left: 0;
    top: 50%;
    // width: 100%;
    height: 85%;
    transform: translateY(-50%) skewY(-10deg);
    transform-origin: center;
    overflow: hidden;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);

    .slides {
      position: absolute;
      left: 0;
      top: -10%;
      width: 100vw;
      height: 100vh;
      animation: 11s slideshow-hero-mobile -3s infinite;
    }

    .slide1 {
      background-image: url(../images/slider/slide1.jpg);
      animation: 20s slides-set-1 -0.1s infinite;
    }
    .slide2 {
      background-image: url(../images/slider/slide2.jpg);
      animation: 20s slides-set-2 -0.1s infinite;
    }
    .slide3 {
      background-image: url(../images/slider/slide3.jpg);
      animation: 20s slides-set-3 -0.1s infinite;
    }

    @media (min-width: 600px) {
      left: 50%;
      top: 0;
      width: 67%;
      height: 100%;
      transform: translateX(-50%) skewX(-10deg);

      .slides {
        top: 0;
        left: -25%;
        height: 100%;
        animation: 11s slideshow-hero -3s infinite;
      }
    }
  }

  &--contrast {
    z-index: 1;
    width: 100%;
    height: 50%;
    transform: none;
    
    .slide {
      // filter: blur(3px);
    }

    &--before {
      left: 0;

      .slides {
        width: 100vw;
      }
    }

    &--after {
      z-index: 2;
      left: auto;
      right: 0;

      .slides {
        width: 100vw;
        left: auto;
        right: 0;
      }
    }

    .slides {
      animation: 14s slideshow-contrast -5s infinite;
    }

    .slide1 {
      background-image: linear-gradient(to bottom, rgba(200,200,75,0.25) 0, rgba(200,75,75,0.5) 100%), url(../images/slider/slide1.jpg);
      // background-image: url(../img/slideshow/hero-1.jpg);
      animation: 20s slides-set-1 -0.2s infinite;
    }
    .slide2 {
      background-image: linear-gradient(to bottom, rgba(200,200,75,0.25) 0, rgba(200,75,75,0.5) 100%), url(../images/slider/slide2.jpg);
      // background-image: url(../img/slideshow/hero-2.jpg);
      animation: 20s slides-set-2 -0.2s infinite;
    }
    .slide3 {
      background-image: linear-gradient(to bottom, rgba(200,200,75,0.25) 0, rgba(200,75,75,0.5) 100%), url(../images/slider/slide3.jpg);
      // background-image: url(../img/slideshow/hero-3.jpg);
      animation: 20s slides-set-3 -0.2s infinite;
    }

    &--after {
      top: auto;
      bottom: 0;

      .slides {
        animation: 13s slideshow-contrast -13s infinite;
      }
      .slide {
        background-position: right;
      }
      .slide1 {
        animation: 20s slides-set-1 infinite;
      }
      .slide2 {
        animation: 20s slides-set-2 infinite;
      }
      .slide3 {
        animation: 20s slides-set-3 infinite;
      }
    }

    @media (min-width: 600px) {
      width: 50%;
      height: 100%;

      &--after {
        top: 0;
        bottom: auto;

        .slides {
          width: 50vw;
        }
      }
    }
  }
}

.slides,
.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes slideshow-hero-mobile {
  0% {
    transform: scale(1) skewY(10deg);
  }
  50% {
    transform: scale(1.05) skewY(10deg);
  }
  100% {
    transform: scale(1) skewY(10deg);
  }
}

@keyframes slideshow-hero {
  0% {
    transform: scale(1) skewX(10deg);
  }
  50% {
    transform: scale(1.05) skewX(10deg);
  }
  100% {
    transform: scale(1) skewX(10deg);
  }
}

@keyframes slideshow-contrast {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes slides-set-1 {
  0% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  31% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  34% {
    opacity: 0;
    // transform: translateY(100px);
    transform: scale(1.05);
  }
  97% {
    opacity: 0;
    // transform: translateY(-100px);
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
}
@keyframes slides-set-2 {
  0% {
    opacity: 0;
    // transform: translateY(-100px);
    transform: scale(1.05);
  }
  31% {
    opacity: 0;
    // transform: translateY(-100px);
    transform: scale(1.05);
  }
  34% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  64% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  67% {
    opacity: 0;
    // transform: translateY(100px);
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    // transform: translateY(100px);
    transform: scale(1.05);
  }
}
@keyframes slides-set-3 {
  0% {
    opacity: 0;
    // transform: translateY(-100px);
    transform: scale(1.05);
  }
  64% {
    opacity: 0;
    // transform: translateY(-100px);
    transform: scale(1.05);
  }
  67% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  97% {
    opacity: 1;
    // transform: translateY(0);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    // transform: translateY(100px);
    transform: scale(1.05);
  }
}